import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ description, lang, meta, image: metaImage, title, pathname, titleTemplate }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keywords
            siteUrl
          }
        }
      }
    `
  )
  const icons = {
    icon32: {
      publicURL: "/icons/favicon32.png"
    },
    icon64: {
      publicURL: "/icons/favicon64.png"
    },
    icon120: {
      publicURL: "/icons/favicon120.png"
    },
    icon256: {
      publicURL: "/icons/favicon256.png"
    }
  }

  const metaDescription = description || site.siteMetadata.description
  const image =
    metaImage && metaImage.src
      ? `${site.siteMetadata.siteUrl}${metaImage.src}`
      : null
  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null
  const links = [{
    rel: "icon",
    href: `${site.siteMetadata.siteUrl}${icons.icon32.publicURL}`,
    sizes: "32x32",
    type: "image/png"
  },{
    rel: "apple-touch-icon",
    href: `${site.siteMetadata.siteUrl}${icons.icon64.publicURL}`
  },{
    rel: "icon",
    href: `${site.siteMetadata.siteUrl}${icons.icon120.publicURL}`,
    sizes: "120x120",
    type: "image/png"
  },{
    rel: "icon",
    href: `${site.siteMetadata.siteUrl}${icons.icon256.publicURL}`,
    sizes: "256x256",
    type: "image/png"
  }]
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={titleTemplate ? titleTemplate : `%s - ${site.siteMetadata.title}`}
      link={
        canonical
          ? links.concat([
            {
              rel: "canonical",
              href: canonical,
            },
          ]) : links
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: "keywords",
          content: site.siteMetadata.keywords.join(","),
        },
        {
          name: "msapplication-TileImage",
          content: `${site.siteMetadata.siteUrl}${icons.icon256.publicURL}`
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          metaImage
            ? [
              {
                property: "og:image",
                content: image,
              },
              {
                property: "twitter:image",
                content: image,
              },
              {
                property: "og:image:width",
                content: metaImage.width,
              },
              {
                property: "og:image:height",
                content: metaImage.height,
              },
              {
                name: "twitter:card",
                content: "summary_large_image",
              },
            ]
            : [
              {
                name: "twitter:card",
                content: "summary",
              },
            ]
        )
        .concat(meta)}
    />
  )
}

Seo.defaultProps = {
  lang: `ru`,
  meta: [],
  description: false,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
  pathname: PropTypes.string,
}

export default Seo

