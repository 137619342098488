import * as React from "react";
import Header from "../components/header";
import * as Styles from "./index.module.sass";
import Transition from "../components/transition";
import { ContextProviderComponent } from "./context";

const Layout = ({ location, children }) => {
  return (
    <ContextProviderComponent>
      <section className={`section is-paddingless ${Styles.section}`}>
        <Header />
        <Transition location={location}>{children}</Transition>
      </section>
    </ContextProviderComponent>
  );
};
export default Layout;
